import React from "react"
import { graphql } from "gatsby"

import theme from "../styles/theme"
import { Layout } from "../components/Layout"
import { IconBack, IconRight, IconLink } from "../components/blog/BlogIcons"
import {
  InnerLinkButton,
  OuterLinkButton,
} from "../components/blog/BlogLinkButton"
import {
  StyledWrap,
  StyledTitleWrapper,
  StyledContentsWrapper,
  StyledLinkWrapper,
} from "../components/blog/BlogStyles"

const InformationBlog = ({ data }) => {
  return (
    <Layout>
      <StyledWrap>
        <StyledTitleWrapper>
          <h2 className="title">{data.info.infoTitle}</h2>
          <span className="pubDate">{data.info.pubDate}</span>
        </StyledTitleWrapper>
        <StyledContentsWrapper
          dangerouslySetInnerHTML={{ __html: data.info.infoContents }}
        />

        <StyledLinkWrapper>
          <InnerLinkButton to="/">
            <IconBack />
            トップに戻る
          </InnerLinkButton>

          {data.info.infoLink && (
            <InnerLinkButton
              to={data.info.infoLink}
              bgcolor={theme.colors.primary.red}
            >
              <IconRight />
              詳細を見る
            </InnerLinkButton>
          )}
          {data.info.infoLinkOutUrl && (
            <OuterLinkButton
              to={data.info.infoLinkOutUrl}
              bgcolor={theme.colors.primary.green}
            >
              <IconLink />
              ページを見る
            </OuterLinkButton>
          )}
        </StyledLinkWrapper>
      </StyledWrap>
    </Layout>
  )
}

export const query = graphql`
  query FrancoisInformationQuery($slug: String) {
    info: strapiFrancoisInformation(slug: { eq: $slug }) {
      slug
      infoTitle
      pubDate
      infoContents
      infoLink
      infoLinkUrl
      infoLinkOutUrl
    }
  }
`

export default InformationBlog
